.selectionWrapper{
    margin-top:10px;
    background-color: var(--elements-color);
    display: flex;
    align-items: center;
    width:30%;
    border-radius:5px;
    padding:20px;
    box-shadow:var(--border-shadow-color);
}
.selectionWrapper select{
    border:none;
    background-color: var(--elements-color);
    width:100%;
    height:100%;
    margin-right:5px;
    color:var(--input-color);
}