.header{
    background-color: var(--elements-color);
    display: flex;
    justify-content: space-between;
    box-shadow: var(--heavier-border-shadow-color);
    align-items: center;
    height:5em;
    z-index:10;
    position: relative;
}

.header div{
    background-color: var(--elements-color);
}

.header .headerLogo{
    margin-left: 1.5em;
    font-weight: 900;
    font-size: 24px;
    cursor: pointer;
}

.header div{
    font-size: 18px;
    margin-right: 2em;
}

.themeMode{
    cursor: pointer;
}