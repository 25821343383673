@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');

* {
  background-color: var(--background-color);
  color:var(--text-color)
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 300,600,800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --text-color:hsl(200, 15%, 8%);
  --background-color: hsl(0, 0%, 98%);
  --elements-color:hsl(0, 0%, 100%);
  --input-color:hsl(0, 0%, 52%);
  --border-shadow-color:rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --heavier-border-shadow-color:rgba(36, 38, 41, 0.2) 0px 8px 24px;

}

::placeholder{
  color: var(--input-color)
}