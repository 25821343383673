.card{
    display: flex;
    margin: 25px;
    border-radius: 5px;
    box-shadow: var(--border-shadow-color);
    height: 320px;
    width: 280px;
    background-color: var(--elements-color);
    flex-direction: column;
}

.card *{
    background-color: var(--elements-color);
}

.card:hover{
    cursor: pointer;
    box-shadow: var(--heavier-border-shadow-color);
    transition: 0.5s;
}

.cardBody{
    margin:20px;
}

.cardHeader{
    margin-bottom:20px
}

.cardFlag{
    height:50%;
    width:100%;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
}

.cardFlag img{
    height: 160px;
    width: 280px;
    object-fit: fill;
}

.cardFlag{
    overflow: hidden;
}

.cardKeyword{
    font-weight: 600;
}