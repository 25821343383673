.flagSection{
    display: inline-flex;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    z-index: 10;
    flex-grow: 0.5;
}

.countryPageBody *{
    font-size: 18px;
}

.countryTitle{
    font-size:2em;
}

.flagSection img{
    object-fit: contain;
    width:100%;
    height:100%;

}

.body{
    height: 25em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.informationSection{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width:45em;
}

.informationSection ul{
    font-size: 16px;
    list-style: none;
    padding: 0px;
    font-size: 16px;
}
.informationSection li{
    display: inline-block;
    width: 45%;
    padding: 5px 0px
}

@media only screen and (max-width: 650px) {
    .informationSection li{
        display: block;
        width:100%;
    }
  }

.fontweight600{
    font-weight: 600;
}


.buttonWrapperIcon{
    background-color: var(--elements-color);
}

.backButtonWrapper{
    margin-top: 50px;
    margin-bottom:50px;
    width:100px;

}

.buttonWrapper{
    background-color: var(--elements-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    height:40px;
    padding: 0px 10px;
    box-shadow:var(--border-shadow-color);
}
.buttonWrapper button{
    border:none;
    background-color: var(--elements-color);
    color:var(--text-color);
}

.buttonWrapper:hover *{
    cursor: pointer;
    transition: 0.5s;
}

.buttonWrapper:hover{
    box-shadow:var(--heavier-border-shadow-color);
    transition: 0.5s;
    cursor: pointer;
}

.buttonBorderContainer{
    padding-left: 10px;
    padding-right: 10px;;
    margin-left:10px;
    margin-top: 10px;
}

.borderCountriesWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
}

.borderCountriesButtonWrapper{
    display: flex;
    margin-left:10px;
    flex-wrap: wrap;
}